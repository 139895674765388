<template>
  <!-- 隐患排查清单 -->
  <div class="container">
    <!-- //隐患排查弹框 -->
    <!-- <el-dialog
      title="隐患排查"
      :visible.sync="screeningdialogVisible"
      width="30%"
    >
      <el-form
        ref="screeningFormData"
        :model="screeningFormData"
        label-width="80px"
      >
        <el-form-item label="排查任务 : ">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="班中巡检"></el-checkbox>
            <el-checkbox label="班中交接班"></el-checkbox>
            <el-checkbox label="每半年/企业"></el-checkbox>
            <el-checkbox label="每周/项目部"></el-checkbox>
            <el-checkbox label="每月/企业"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="screeningdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="screeningSubmit">确 定</el-button>
      </span>
    </el-dialog> -->
    <!-- //隐患排查结束 -->
    <el-card v-if="cons == 1">
      <div class="search">
        <el-row>
          <el-col :span="6">
            <span>项目名称 : </span>
            <el-input v-model="riskname" placeholder="请输入项目名称"></el-input>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="queryday1">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
            <el-button type="success" @click="exportExcel">导出表格</el-button>
            <!-- <el-button type="primary" @click="openDialog">添加风险</el-button> -->
          </el-col>
        </el-row>
      </div>
      <div class="main">
        <el-table :data="tableData" stripe style="width: 100%; border-radius: 5px; padding-top: 5px"
          :header-cell-style="{ background: '#4d7cfe', color: '#ffffff' }">
          <el-table-column prop="name" label="项目名称" align="center">
          </el-table-column>
          <el-table-column prop="corporations" label="所属公司" align="center">
          </el-table-column>
          <el-table-column prop="site" label="所属区域" align="center">
          </el-table-column>
          <el-table-column prop="hidden_jc" label="基础管理类清单" align="center">
          </el-table-column>
          <el-table-column prop="hidden_sc" label="生产现场类清单" align="center">
          </el-table-column>
          <el-table-column prop="hidden_num" label="清单统计" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-link type="primary" :underline="false" @click="dianji(scope.row.id)">查看详情</el-link>
              <!-- <el-button type="primary" @click="tianjia(scope.row.id)"
                  >隐患排查</el-button> -->
            </template>
          </el-table-column>

          <!-- <el-table-column
            prop="details.hazard"
            label="主要危险因素"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="details.projectrola"
            label="工程措施"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="details.controls"
            label="管理措施"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="details.personage"
            label="个体防护"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="details.manager"
            label="应急措施"
            align="center"
          >
          </el-table-column> -->
          <!-- <el-table-column label="操作" align="center" width="400px">
              <template slot-scope="scope">
                <el-button type="primary" @click="editRisk(scope.row)"
                  >修改</el-button
                >
                <el-button type="primary" @click="openControls(scope.row)"
                  >分级管控</el-button
                >
                <el-button type="primary" @click="openScreening(scope.row)"
                  >隐患排查</el-button
                >
                <el-button type="danger" @click="deleteRisk(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column> -->
        </el-table>
        <!-- //分页 -->
        <el-pagination @size-change="handleCurrentChange1" @current-change="handleSizeChange1" :current-page="page"
          :page-sizes="[5, 10, 20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
          :total="totalz">
        </el-pagination>
      </div>
    </el-card>
    <!-- 第二个卡片 -->
    <el-card v-if="cons == 2">
      <div class="but">
        <el-button type="primary" round size="mini" @click="retu" class="butt">返回上一级</el-button>
      </div>
      <div id="con-ul">
        <ol>
          <li>| 低风险统计<br /><br /><span>{{ card_top.count_di }}</span></li>
          <li>| 一般风险统计<br /><br /><span>{{ card_top.count_yb }}</span></li>
          <li>| 重大风险统计<br /><br /><span>{{ card_top.count_zd }}</span></li>
          <li>| 较大风险统计<br /><br /><span>{{ card_top.count_jd }}</span></li>
          <li>| 风险总计<br /><br /><span>{{ card_top.count_zj }}</span></li>
        </ol>
      </div>
      <!-- //选项卡切换 -->
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="基础管理类" name="1">
          <!-- //基础管理类卡片 -->
          <el-card>
            <!-- <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="项目名称:">
                <el-input placeholder="请输入项目名称" v-model="ManagementQuery" size="small"></el-input>
              </el-form-item>
              <el-form-item style="margin-left: 20px">
                <el-button type="primary" size="small" @click="queryday2">查询</el-button>
                <el-button type="warning" size="small" @click="resetday2">重置</el-button>
              </el-form-item>
            </el-form> -->
            <!-- //表单 -->
            <el-table :data="tableDatatwo" stripe style="width: 100%"
              :header-cell-style="{ background: '#0000CD', color: '#ffffff' }">
              <el-table-column prop="jobName" label="排查项目" align="center">
              </el-table-column>
              <el-table-column prop="jobContent" label="排查内容及排查标准" align="center">
              </el-table-column>
              <el-table-column prop="zx_bn_qy" label="专项检查(每半年/企业)" align="center">
                <template slot-scope="scope">
                  <i v-if="scope.row.bn == 1" class="el-icon-check"></i>
                </template>
              </el-table-column>
              <el-table-column prop="zh_my_qy" label="综合性检查(每月/企业)" align="center">
                <template slot-scope="scope">
                  <i v-if="scope.row.my == 1" class="el-icon-check"></i>
                </template>
              </el-table-column>
              <el-table-column prop="zh_mz_xm" label="专项检查(每周/项目部)" align="center">
                <template slot-scope="scope">
                  <i v-if="scope.row.zx == 1" class="el-icon-check"></i>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="" label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" @click="tianjia(scope.row.id)"
                    >隐患排查</el-button
                  >
                </template> -->
              <!-- </el-table-column> -->
            </el-table>
            <!-- //分页 -->
            <el-pagination @size-change="handleCurrentChange" @current-change="handleSizeChange" :current-page="page"
              :page-sizes="[5, 10, 20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
              :total="totaltwo">
            </el-pagination>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="作业活动类" name="2">
          <!-- //作业活动类卡片 -->
          <el-card>
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="类型:">
                <el-input placeholder="请输入作业类型" v-model="name" size="small"></el-input>
              </el-form-item>
              <el-form-item label="风险等级:">
                <el-select v-model="type" placeholder="请选择" size="small">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="margin-left: 20px">
                <el-button type="primary" size="small" @click="queryday3">查询</el-button>
                <el-button type="warning" size="small" @click="resetday3">重置</el-button>
              </el-form-item>
            </el-form>
            <!-- //表单 -->

            <el-table :data="tableDatathree" stripe style="width: 100%" :header-cell-style="{
      background: '#4d7cfe',
      color: '#ffffff',
      textAlign: 'center',
    }" :cell-style="cellStyle">
              <el-table-column label="风险点">
                <el-table-column label="类型" prop="riskname" align="center"></el-table-column>
                <el-table-column label="名称" prop="risktype" align="center"> </el-table-column>
                <el-table-column prop="riskrank" label="风险等级" align="center"> </el-table-column>
                <el-table-column label="作业步骤" prop="detail.controls" align="center" show-overflow-tooltip>
                </el-table-column>
              </el-table-column>
              <el-table-column label="排查内容与排查标准">
                <el-table-column label="主要危险有害因素（人、物、环、管)" width="300" prop="detail.hazard" align="center"
                  show-overflow-tooltip></el-table-column>
                <el-table-column label="管理措施" width="300" prop="detail.manager" align="center"></el-table-column>
              </el-table-column>
              <el-table-column prop="province" label="日常检查">
                <el-table-column label="班中巡查" prop="hidden_item.bzxj" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.hidden_item.bzxj == 1 ? true : false"></i>
                  </template>
                </el-table-column>
                <el-table-column label="班中交接班" prop="hidden_item.bzjjb" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.hidden_item.bzjjb == 1 ? true : false"></i>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="province" label="专项检查">
                <el-table-column label="每半年/企业" prop="hidden_item.mbn" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.hidden_item.mbn == 1 ? true : false"></i>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop="province" label="综合性检查">
                <el-table-column label="每月/企业" prop="hidden_item.my" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.hidden_item.my == 1 ? true : false"></i>
                  </template>
                </el-table-column>
                <el-table-column label="每周/项目部" prop="hidden_item.mz" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.hidden_item.mz == 1 ? true : false"></i>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
            <!-- //分页 -->
            <el-pagination @size-change="handleCurrentChange" @current-change="handleSizeChange" :current-page="page"
              :page-sizes="[5, 10, 20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="设备设施类" name="3">
          <!-- //第三个卡片 -->
          <el-card>
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="类型:">
                <el-input placeholder="请输入作业类型" v-model="name" size="small"></el-input>
              </el-form-item>
              <el-form-item label="风险等级:">
                <el-select v-model="type" placeholder="请选择" size="small">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="margin-left: 20px">
                <el-button type="primary" size="small" @click="queryday4">查询</el-button>
                <el-button type="warning" size="small" @click="resetday4">重置</el-button>
              </el-form-item>
            </el-form>
            <!-- //表单 -->

            <el-table :data="tableDatathree1" stripe style="width: 100%" :header-cell-style="{
      background: '#4d7cfe',
      color: '#ffffff',
      textAlign: 'center',
    }" :cell-style="cellStyle">
              <el-table-column label="风险点">
                <el-table-column label="类型" prop="riskname" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="名称" prop="risktype" align="center"> </el-table-column>
                <el-table-column label="风险等级" prop="riskrank" align="center"> </el-table-column>
                <el-table-column label="检查项目" prop="detail.controls" show-overflow-tooltip> </el-table-column>
              </el-table-column>
              <el-table-column prop="province" label="排查内容与排查标准">
                <el-table-column label="标准" prop="detail.hazard" width="300" show-overflow-tooltip
                  align="center"></el-table-column>
                <el-table-column label="管理措施" prop="detail.controls" width="300" show-overflow-tooltip
                  align="center"></el-table-column>
              </el-table-column>
              <el-table-column prop="province" label="日常检查">
                <el-table-column label="班中巡查" prop="hidden_item.bzxj" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.hidden_item.bzxj == 1 ? true : false"></i>
                  </template>
                </el-table-column>
                <el-table-column label="班中交接班" prop="hidden_item.bzjjb" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.hidden_item.bzjjb == 1 ? true : false"></i>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="专项检查">
                <el-table-column label="每半年/企业" prop="hidden_item.mbn" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.hidden_item.mbn == 1 ? true : false"></i>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="综合性检查">
                <el-table-column label="每月/企业" prop="hidden_item.my" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.hidden_item.my == 1 ? true : false"></i>
                  </template>
                </el-table-column>
                <el-table-column label="每周/项目部" prop="hidden_item.mz" align="center">
                  <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.hidden_item.mz == 1 ? true : false"></i>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
            <!-- //分页 -->
            <el-pagination @size-change="handleCurrentChange" @current-change="handleSizeChange" :current-page="page"
              :page-sizes="[5, 10, 20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import {
  addHiddenInfo,
  addHiddenIn,
  BasiManagement,
  screeningToRisk,
  ActivityRendering,
} from "../../../request/hidden";
export default {
  data() {
    return {
      tableData: [],
      tableDatatwo: [],
      tableDatathree: [],
      tableDatathree1: [],
      total: 0,  //分页总条数
      totaltwo: 0, //分页总条数
      porject_id: 0,
      card_top: [],//隐患排查清单day1卡片数据
      page: 1,
      pagesize: 10,
      cons: 1, //判断如果是1的话显示第一个卡片
      activeName: '1', //默认被选中选项
      screeningdialogVisible: false, //隐患排查添加弹框
      checkList: [], //隐患排查多选数组
      screeningFormData: {
        bzxj: 0,
        bzjjb: 0,
        mbn: 0,
        mz: 0,
        my: 0,
        rid: 0,
      }, //隐患排查表单
      info: {
        sb_hd: '',
        risk_type: '',
        risk_name: '',
        page: 1,
        pagesize: 5,

      },//作业活动类数据
      Risklevel: [{
        label: "",
        value: ""
      }],//地址位置
      riskname: "",//项目名称
      risktype: "",//公司名称
      riskrank: "",//所在位置
      riskrank1: "",
      ManagementQuery: "",//项目查询
      type: "",//作业活动类类型的input值
      name: "",//作业活动类中风险等级下拉框的值
      pjtid: '',
      options: [{
        label: "低风险",
        value: "低风险"
      }, {
        label: "一般风险",
        value: "一般风险"
      }, {
        label: "较大风险",
        value: "较大风险"
      }, {
        label: "重大风险",
        value: "重大风险"
      }]

    };
  },
  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.cons = 1;
      this.getRisk();
    },
  },

  created() { },
  mounted() {
    this.getRisk();
  },
  methods: {
    // 获取隐患排查清单数据
    async getRisk() {
      let msg = {
        unique_identification: localStorage.getItem("uid"),
        name: this.riskname,
        size: this.pagesize,
        page: this.page,
      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project')
        }
      }
      const res = await addHiddenInfo(msg);
      this.totalz = res.data.count;
      this.tableData = res.data.data;
    },
    handleCurrentChange1(val) {
      this.pagesize = val;
      this.getRisk()
    },
    handleSizeChange1(val) {
      this.page = val;
      this.getRisk()
    },
    //分页卡片1
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.page = val;
      this.handleClick();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pagesize = val;
      // this.handleClick(val)
      this.handleClick();
    },
    //点击查看详情跳转到第二个卡片
    async dianji(id) {
      this.porject_id = id
      let msg = {
        size: this.pagesize,
        page: this.page,
        porject_id: this.porject_id,
      }
      const res = await addHiddenIn(msg);
      this.totaltwo = res.data.count;
      this.card_top = res.data; //top卡片数据
      this.tableDatatwo = res.data.data;
      this.handleClick();
      // console.log(this.tableDatatwo, 123456);
      this.cons = 2;
    },
    //返回上一级
    retu() {
      this.cons = 1;
    },
    async handleClick() {
      if (this.activeName == 1) {
        let msg = {
          size: this.pagesize,
          page: this.page,
        }
        const res = await BasiManagement(msg);
        this.totaltwo = res.data.count;
        this.tableDatatwo = res.data.data;
      }
      if (this.activeName == 2) {
        this.info.sb_hd = 1
        let msg = {
          project_id: this.porject_id,
          size: this.pagesize,
          page: this.page,
          sb_hd: this.info.sb_hd,
          type: this.type,
          name: this.name,
        }
        const res = await ActivityRendering(msg);
        this.total = res.data.count //分页总条数
        this.tableDatathree = res.data.data
        // console.log(this.tableDatathree,'作业活动')
      }

      if (this.activeName == 3) {
        this.info.sb_hd = 2
        let msg = {
          project_id: this.porject_id,
          size: this.pagesize,
          page: this.page,
          sb_hd: this.info.sb_hd,
          type: this.type,
          name: this.name,
        }
        const res = await ActivityRendering(msg);
        this.total = res.data.count //分页总条数
        this.tableDatathree1 = res.data.data
        console.log(this.tableDatathree1, 123654)
      }
    },

    // 控制表格风险级别文字颜色
    cellStyle(row, column, rowIndex, columnIndex) {
      //根据报警级别显示颜色
      // console.log(row);  
      // console.log(row.column);
      if (row.column.label == "风险等级" && row.row.riskrank == "低风险") {
        return "color:#0070c0";
      } else if (
        row.column.label == "风险等级" &&
        row.row.riskrank == "一般风险"
      ) {
        return "color:#ffff00";
      } else if (
        row.column.label == "风险等级" &&
        row.row.riskrank == "较大风险"
      ) {
        return "color:#fd5c0c";
      } else if (
        row.column.label == "风险等级" &&
        row.row.riskrank == "重大风险"
      ) {
        return "color:#d8090f";
      }
    },

    //day1查询搜索
    async queryday1() {
      this.page = 1
      this.getRisk();
    },
    //重置
    reset() {
      this.riskname = '';
      this.getRisk();
    },
    //day2查询搜索
    async queryday2() {
      this.dianji(this.porject_id);
    },
    //resetday2重置
    async resetday2() {
      this.ManagementQuery = '';
      var msg = {
        page: this.page,
        size: this.pagesize,
        porject_id: this.porject_id,
      };
      const res = await addHiddenIn(msg);
      // console.log(res,159)
      this.totaltwo = res.data.count;
      this.card_top = res.data; //top卡片数据
      this.tableDatatwo = res.data.data;
      // console.log(this.tableDatatwo, 123456);
      this.cons = 2;
    },
    //day3查询
    async queryday3() {
      this.handleClick();
    },
    //day3重置
    resetday3() {
      this.type = '';
      this.name = '';
      this.handleClick();
    },
    //day4查询
    async queryday4() {
      this.handleClick();
    },

    //day4重置
    resetday4() {
      this.type = '';
      this.name = '';
      this.handleClick();
    },

    async exportExcel() {
      let msg = {
        unique_identification: localStorage.getItem("uid"),
        name: this.riskname,
        size: 10000000,
        page: 1,
      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project')
        }
      }
      const res = await addHiddenInfo(msg);
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../../vendor/Export2Excel"); //引入文件
        const tHeader = ["项目名称", "所属公司", "所属区域", "基础管理类清单", "生产现场类清单", "清单统计",]; //将对应的属性名转换成中文 //const tHeader = [];
        const filterVal = ["name", "corporations", "site", "hidden_jc", "hidden_sc", "hidden_num",]; //table表格中对应的属性名
        const list = res.data.data; //想要导出的数据
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "列表excel");
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    ///////////////////////////////////////////////////////////
    //添加的数据
    // async tianjia(id) {
    //   this.screeningFormData.rid = id;
    //   this.screeningdialogVisible = true;
    // },

    // // 提交隐患排查
    // async screeningSubmit() {
    //   for (let i = 0; i < this.checkList.length; i++) {
    //     if (this.checkList[i] == "班中巡检") {
    //       this.screeningFormData.bzxj = 1;
    //     } else if (this.checkList[i] == "班中交接班") {
    //       this.screeningFormData.bzjjb = 1;
    //     } else if (this.checkList[i] == "每半年/企业") {
    //       this.screeningFormData.mbn = 1;
    //     } else if (this.checkList[i] == "每周/项目部") {
    //       this.screeningFormData.mz = 1;
    //     } else if (this.checkList[i] == "每月/企业") {
    //       this.screeningFormData.my = 1;
    //     }
    //   }
    //   console.log(this.screeningFormData);
    //   const res = await screeningToRisk(this.screeningFormData);
    //   // console.log(res,9999999999999);
    //   if (res.data.code == 200) {
    //     this.$message.success("添加隐患排查成功!");
    //     this.screeningdialogVisible = false;
    //   }
    // },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.main {
  margin-top: 20px;
  padding: 20px;
}

.search {
  .el-input {
    width: 60%;
  }
}

.addForm {
  .el-input {
    width: 65%;
  }
}

.controlsDialog {
  .el-input {
    width: 52%;
  }
}

ol>li {
  list-style: none;
  width: 160px;
  height: 104px;
  float: left;
  margin-left: 10px;
  padding: 20px 20px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}

ol>li>span {
  margin: 10px;
}

ol>li:nth-child(1) {
  width: 160px;
  height: 109px;
  background-image: url(../../../assets/img/risk6.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

ol>li:nth-child(2) {
  width: 160px;
  height: 109px;
  background-image: url(../../../assets/img/risk1.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

ol>li:nth-child(3) {
  width: 160px;
  height: 109px;
  background-image: url(../../../assets/img/risk2.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

ol>li:nth-child(4) {
  width: 160px;
  height: 109px;
  background-image: url(../../../assets/img/risk3.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

#con-ul>ol>li:nth-child(5) {
  width: 160px;
  height: 109px;
  z-index: -10;
  background-image: url(../../../assets/img/risk4.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
}

.but {
  width: 100%;
  height: 40px;
  position: relative;
}

.butt {
  position: absolute;
  right: 0;
}

#con-ul {
  width: 100%;
  height: 120px;
}

.retu {
  margin-bottom: 10px;
}
</style>