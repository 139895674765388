//隐患排查
import { request } from "./request"

// 渲染隐患排查数据
export function addHiddenInfo(msg) {
    return request({
        url: "v3/008/",
        method: "post",
        data: msg,
    })
}
//点击查看详情
export function addHiddenIn(msg) {
    return request({
        url: "api/hdd_m/",
        method: "get",
        params: msg,
    })
}
//基础管理类请求
export function BasiManagement(msg){
    return request({
        url:"api/PRO_jobName/",
        method:"post", 
        data:msg
    })
}
//作业活动类数据渲染
// export function ActivityRendering(msg){
//     return request({
//         url: "api/ris_w/",
//         method: "get",
//         params: msg,
//     })
// }
export function ActivityRendering(msg) {
    return request({
        url: "api/hdd_p/",
        method: "get",
        params: msg,
    })
}
////////RecordsFebruary
// // 添加隐患排查记录
export function addHiddenInfo1(msg) {
    return request({
        url: "v3/009/",
        method: "post",
        data: msg,
    })

}
// // 添加隐患排查二
export function addHiddenInfo13(msg) {
    return request({
        url: "api/hdd_t/",
        method: "get",
        params: msg,
    })

}

//隐患治理台账
export function addhiddenGovernance(msg) {
    return request({
        url: "v3/010/",
        method: "get",
        params: msg,
    })
};
//隐患治理台账day2
export function addhiddenGovernance2(msg) {
    return request({
        url: "api/hdd_a/",
        method: "post",
        data: msg,
    })
};

//重大隐患治理台账
export function addMajorHiddenGovernance(msg) {
    return request({
        url: "v3/011/",
        method: "get",
        params: msg,
    })
}
export function addMajorHiddenday2(msg) {
    return request({
        url: "api/hdd_a/",
        method: "post",
        data: msg,
    })
}



// // 获取排查记录
export function get_work_data_examination_data(msg) {
    return request({
        url: "api/get_work_data_examination_data/",
        method: "post",
        data: msg,
    })

}


// // 获取排查记录
export function get_data_examination_data(msg) {
    return request({
        url: "api/get_work_data_examination_data/",
        method: "get",
        params: msg,
    })

}


// // 获取排查记录
export function deldata_examination_data(msg) {
    return request({
        url: "api/get_work_data_examination_data/",
        method: "put",
        data: msg,
    })

}

// // 获取排查记录
export function show_fankui(msg) {
    return request({
        url: "api/show_fankui/",
        method: "post",
        data: msg,
    })

}


// // 获取排查记录
export function hidddel(msg) {
    return request({
        url: "api/hidd/del/",
        method: "post",
        data: msg,
    })

}